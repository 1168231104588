<template>
  <dialog-edit :value="value" @input="$emit('input')" maxWidth="1300px">
    <template v-slot:title>
      {{ data.name }}
      <small v-if="id"> ({{ id }})</small>
    </template>
    <div style="text-align: initial" ref="contentWrapper"></div>
    <a-loader v-if="loading" />
    <template v-if="!loading" v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
    <a-view-video v-if="fullVideo" v-model="fullVideo" :src="srcVideo" />
  </dialog-edit>
</template>

<script>
export default {
  props: {
    old: { type: Boolean, default: false },
    value: Boolean,
    id: Number,
  },
  data() {
    return { 
      api1: "/mechti/information_group",
      data: {},
      loading: false,
      fullVideo: false,
      srcVideo: "",
    };
  },
  computed: {
    api() {
      if (this.old) return "/mechti/information";
      return "/mechti/information_group";
    },
    content() {
      let res = `<style>
h1,h2,h3,h4 {  margin: 10px 0 20px 0; }
ul,ol {margin-bottom: 16px;}  
ul {   list-style-type: disc; }
ol {   list-style-type: decimal;}
</style>`;
      console.log("content", this.old);

      if (this.old) {
        res += this.data.content;
      } else if (this.data.information_data && this.data.information_data.info) {
        for (let i = 0; i < this.data.information_data.info.length; i++) {
          const id = this.data.information_data.info[i];
          const el = this.data.info.find(f => f.id == id);
          console.log(el?.content);
          res += (res ? "<br><hr></hr><br>" : "") + el?.content || "";
        }
      }
      return res;
    },
  },
  created() {
    if (this.id) this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      const r = await this.$axios.get(this.api + "/" + this.id);
      this.data = r.data.data;
      this.loading = false;
      this.$refs.contentWrapper.innerHTML = this.content;
      const videoElement = this.$refs.contentWrapper.querySelector("video");
      //videoElement.addEventListener("click", this.handleVideoClick);
    },
    handleVideoClick(event) {
      event.preventDefault;
      // Обработка клика по видео
      const videoElement = event.target;
      // Получаем значение атрибута src у элемента <video>
      const videoSource = videoElement.firstElementChild.getAttribute("src");

      console.log("Клик по видео", videoSource);
      if (videoSource) {
        this.srcVideo = videoSource;
        this.fullVideo = true;
      }
    },
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4 {
  margin: 10px 0 20px 0;
}
.video-container {
  width: 100%;
}

.video-container video {
  width: 100%;
  height: auto;
}
</style>
